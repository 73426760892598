<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Agents</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Agents
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('agent', 'create')"
                      @click="createUser()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by Name, Unique Identifier, Email or Business email"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Contact"
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchUser"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                         @click="searchUser"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" :class="sortedClass('first_name')" @click="sortBy('first_name')">Full name</th>
                  <th>Business</th>
                  <th>Contact</th>
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th>Last login</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index">
                    <td>
                      <router-link :to="{name:'user-agent-profile', params:{id:item.id}}"
                                   class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.full_name }}
                      </router-link>
                      <v-icon small color="blue" class="fas fa-location-pin" v-if="item.is_access_location"></v-icon>
                        <p><b>{{ item.unique_identifier }}</b></p>
                    </td>

                    <td>
                        <p><b>{{ item.business_name }}</b><br/>
                            {{ item.city_of_recruitment }}</p>
                    </td>

                    <td>
                        {{ item.email }} <br>
                        {{ item.mobile || item.phone }}
                    </td>

                    <td v-if="currentUser && currentUser.access_type == 'head'">
                        {{ item.branch_name || 'N/A' }}
                    </td>

                    <td>
                      {{ item.formatted_last_login || 'N/A' }}
                    </td>

                    <td>
                        <span class="badge text-lg"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">
                              <a @click="updateAccessLocation(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-location</v-icon>
                                  </span>
                                <span class="navi-text">{{ item.is_access_location ? "Disable access location" : "Enable access location"}}</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'show')">
                              <a @click="loginAsAgent(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-user-lock</v-icon>
                                  </span>
                                <span class="navi-text">Login as agent</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'edit')">
                              <a @click="changePassword(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-key</v-icon>
                                  </span>
                                <span class="navi-text">Change password</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'edit')">
                              <a @click="deactivateOrActivateAgent(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-user-xmark</v-icon>
                                  </span>
                                <span class="navi-text">{{ item.is_active ? "Deactivate" : "Activate"  }} agent</span>
                              </a>
                            </b-dropdown-text>

<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">-->
<!--                              <a @click="updateFutureLead(item)" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                      <v-icon color="blue darken-2">fas fa-school</v-icon>-->
<!--                                  </span>-->
<!--                                <span class="navi-text">{{ item.is_future_lead ? "Unmark as future lead" : "Mark as future lead"}}</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'edit')">
                              <a @click="editUser(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('subscription', 'show')">
                              <a @click="manageSubscription(item.id,item.plan_id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-user-plus</v-icon>
                                  </span>
                                <span class="navi-text">Manage subscription</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'delete')">
                              <a class="navi-link" @click.prevent="deleteUser(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUsers"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Manage Subscription</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select dense outlined :items="subscriptions"
                            label="Manage Subscription" item-text="title" :error="$v.subscription.plan_id.$error"
                            item-value="id" v-model="subscription.plan_id"
                            requiredNotifications
                  ></v-select>
                  <span class="text-danger" v-if="$v.subscription.plan_id.$error">Select Subscription</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeManageSubscription">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateSubscription">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <create-and-update ref="create-and-update" @refresh="getAllUsers"></create-and-update>
      <user-password-change ref="user-password-change" @refresh="getAllUsers"></user-password-change>

    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/user/CreateAndUpdate";
import UserService from "@/services/user/UserService";
import SubscriptionService from "@/services/subscription/SubscriptionService";
import {required, numeric} from "vuelidate/lib/validators";
import UserPasswordChange from "@/view/pages/view/user/general/PasswordUpdate";

const subscriptionService = new SubscriptionService();
const user = new UserService();

export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    UserPasswordChange
  },
  validations: {
    subscription: {
      plan_id: {required},
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoading: false,
      users: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      subscriptions: [],
      subscription: {
        plan_id: '',
        user_id: '',
      },
      search: {
        info: '',
        is_active: '1',
        mobile: '',
        agent_application_status: 'approved',
        is_agent: '1',
        entry_type: 'enrolment',
      }
    }
  },
  mounted() {
    this.getAllUsers();
  },
  computed: {
    sortedItems() {
      const list = this.users.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    changePassword(item) {
      this.$refs['user-password-change'].changePassword(item);
    },

    loginAsAgent(id) {
      user.loginAsAgent(id).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Agent may be inactive");
      }).catch(() => {});
    },

    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },

    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    createUser() {
      this.$refs['create-and-update'].createUser('agent');
    },

    editUser(item) {
      this.$refs['create-and-update'].editUser('agent', item);
    },

    getAllUsers() {
      this.loading = true;
      user
          .paginate(this.search, this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchUser() {
      this.getAllUsers();
    },

    deleteUser(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            user
                .delete(id)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("User Agent Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    deactivateOrActivateAgent(item) {
      this.$confirm({
        message: `Are you sure you want to deactivate agent? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_active = !item.is_active;
            user
                .deactivateOrActivateAgent(item.id, {is_active: item.is_active})
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    updateFutureLead(item) {
      this.$confirm({
        message: `Are you sure you want update this ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_future_lead = !item.is_future_lead;
            user
                .update(item.id, item)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    updateAccessLocation(item) {
      this.$confirm({
        message: `Are you sure you want update this ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_access_location = !item.is_access_location;
            user
                .update(item.id, item)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    getAllSubscription() {
      subscriptionService
          .all()
          .then(response => {
            this.subscriptions = response.data.data;
          })
    },
    updateSubscription() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        user
            .manageSubscription(this.subscription.user_id, this.subscription)
            .then(response => {
              this.$snotify.success('Subscription Updated successfully');
              this.closeManageSubscription();
              this.getAllUsers();
            })
      }
    },
    closeManageSubscription() {
      this.dialog = false;
      this.subscription = {
        plan_id: '',
        user_id: '',
      }
      this.$v.$reset();
    },
    manageSubscription(itemId, plan_id = null) {
      this.subscription.user_id = itemId;
      this.subscription.plan_id = plan_id;
      this.dialog = true;
      this.getAllSubscription();
    }
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>